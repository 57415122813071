import React from 'react'

// Third party
import styled from 'styled-components'
import { useMedia } from 'use-media'

const Section = styled.section<{ height: string }>`
  height: ${(props) => props.height};
`

interface WhitespaceProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Whitespace
}

const Whitespace: React.FC<WhitespaceProps> = ({ fields }) => {
  const isTablet = useMedia({ maxWidth: 991 })
  const isMobile = useMedia({ maxWidth: 575 })

  let whitespace: number = fields.desktop ? fields.desktop : 160

  if (isTablet) {
    whitespace = fields.tablet || 0
  }
  if (isMobile) {
    whitespace = fields.mobile || 0
  }

  return <Section height={`${whitespace}px` || '160px'} />
}

export default Whitespace
