import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: any) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  min-height: 100vh;
`

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'nl_NL',
  translations,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider value={{ setIsScrollable, locale, translations }}>
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
