import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg<{ initialWidth: number; initialHeight: number }>`
  @media (min-width: 1400px) {
    width: ${(props) => props.initialWidth}px;
    height: ${(props) => props.initialHeight}px;
  }

  @media (max-width: 1399px) {
    width: ${(props) => props.initialWidth / 1.7}px !important;
    height: ${(props) => props.initialHeight / 1.7}px !important;
  }

  @media (max-width: 1199px) {
    width: ${(props) => props.initialWidth / 2}px !important;
    height: ${(props) => props.initialHeight / 2}px !important;
  }

  @media (max-width: 991px) {
    width: ${(props) => props.initialWidth / 2.5}px !important;
    height: ${(props) => props.initialHeight / 2.5}px !important;
  }

  @media (max-width: 767px) {
    width: ${(props) => props.initialWidth / 3}px !important;
    height: ${(props) => props.initialHeight / 3}px !important;
  }

  @media (max-width: 575px) {
    width: ${(props) => props.initialWidth / 4}px !important;
    height: ${(props) => props.initialHeight / 4}px !important;
  }
`

interface BrandProps {
  id: string
  width: number
  height: number
}

const Brand: React.FC<BrandProps> = ({ id, width, height }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    initialWidth={width}
    initialHeight={height}
    fill="none"
    viewBox="0 0 868 873"
  >
    <g fill="#fff" clipPath={`url(#${id})`}>
      <path d="M371.859 363.822A717.034 717.034 0 00470.826 0H246.45c0 175.428-94.428 339.062-246.45 427.039l112.387 194.317a721.01 721.01 0 00259.472-257.534zM145.218 678.777L257.707 873a493.453 493.453 0 01246.372-66.025 493.454 493.454 0 01246.426 65.821l112.32-194.316a717.861 717.861 0 00-358.845-95.954 717.855 717.855 0 00-358.762 96.259v-.008zM761.55 0H537.149a717.043 717.043 0 0098.966 363.822 720.993 720.993 0 00259.497 257.5L1008 427.005C855.987 339.062 761.55 175.428 761.55 0z"></path>
    </g>
    <defs>
      <clipPath id={id}>
        <path fill="#fff" d="M0 0H1008V873H0z"></path>
      </clipPath>
    </defs>
  </Svg>
)

export default Brand
