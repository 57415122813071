/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled(motion.div)<{ isSticky?: boolean }>`
  @media (min-width: 992px) {
    margin-top: -40px;
    filter: drop-shadow(-3px 47px 19px rgba(0, 0, 0, 0.18));
    background-color: ${({ theme }) => theme.color.light};
    width: 100%;
    left: 0;

    & > div {
      border-radius: 5px;
    }

    & > div {
      background-color: ${({ theme }) => theme.color.light};
    }
  }

  @media (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;
    margin-left: -15%;
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 64.5px;
    height: 64.5px;
  }

  @media (max-width: 991px) {
    width: 34.5px;
    height: 34.5px;
  }
`

const SubmenuItem = styled(NavLink)`
  & span {
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    color: ${({ theme }) => theme.color.dark};
  }

  &:hover span {
    border-bottom: 2px solid ${({ theme }) => theme.color.primary};
    border-right: 2px solid ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    background-color: unset;

    & span {
      font-size: ${({ theme }) => theme.font.size.bigger};
    }
  }

  @media (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.light};

    & span {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Submenu: React.FC<SubmenuProps> = ({ fields }) => {
  return (
    <Wrapper
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
      className="overflow-hidden position-absolute w-100"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              {fields.map((f, index) => (
                <SubmenuItem
                  key={index}
                  to={f?.link?.url || '/'}
                  className="d-flex align-items-center px-3 px-lg-0 py-3"
                >
                  <Icon image={f?.icon} alt="" className="me-4" />
                  <span className="pe-5">{f?.link?.title}</span>
                </SubmenuItem>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Submenu
