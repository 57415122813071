import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonSecondaryCSS = css`
  position: relative;
  background-color: transparent;
  display: inline-block;
  border: 2px solid ${(props) => props.theme.color.primary};
  border-radius: 100px;
  min-width: 124px;
  overflow: hidden;
  z-index: 0;
  transition: border 0.3s;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.primary} !important;
    padding: 7px 25px;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-decoration: none !important;
  }

  &:active {
    transform: translateY(3px);
  }

  &:not([disabled]):hover {
    cursor: pointer;

    & > a,
    & > button {
      color: ${({ theme }) => theme.color.primary} !important;
    }

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
      transform-origin: 200px 200px;
      transform: scale(1) translate(-10px, -100px);
    }
    border: 2px solid #302f2f;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: opacity 0.3s, border 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: #302f2f;
    border-color: transparent;
    border-radius: 50%;
    transform: translate(-10px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.5s, opacity 0.3s, background-color 0.3s;
  }
`

const StyledButton = styled.span`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonSecondary
