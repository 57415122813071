import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

export interface FunctionalitiesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Functionalities
  location?: any
}

interface PostProps {
  [key: string]: any
}

const FunctionalitiesShell: React.FC<FunctionalitiesProps> = ({
  fields,
  location = {},
}) => {
  const types: PostProps = {
    default: loadable(
      () => import('components/flex/Functionalities/Functionalities')
    ),
    image: loadable(
      () => import('components/flex/Functionalities/FunctionalitiesImage')
    ),
    other: loadable(
      () => import('components/flex/Functionalities/FunctionalitiesOther')
    ),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default FunctionalitiesShell
