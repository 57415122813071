import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'
import Brand from 'components/elements/Vectors/Brand'
import { motion, AnimatePresence } from 'framer-motion'

const MenuWrapper = styled(motion.div)`
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.125);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  & ul {
    padding-left: 0;
    margin: 0;
    width: 70%;
    list-style: none;
    z-index: 99;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

const BrandWrapper = styled.div`
  bottom: 5%;
  right: 0;

  & svg {
    opacity: 0.2;
  }
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper
            className="position-fixed d-flex justify-content-center align-items-center"
            exit={{
              opacity: 0,
              y: '100%',
            }}
            initial={{
              opacity: 0,
              y: '100%',
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul className="position-relative">
              <Menu fields={fields} />
            </ul>
            <BrandWrapper className="position-absolute">
              <Brand id="mobile-menu-brand" width={2000} height={1750} />
            </BrandWrapper>
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
