import React from 'react'
import { motion } from 'framer-motion'

interface LogoProps {
  hovered?: boolean
}

const Logo: React.FC<LogoProps> = ({ hovered }) => {
  const animations = {
    open: {
      rotate: 360,
    },
    exit: {
      rotate: 0,
    },
  }

  const transition = {
    yoyo: Infinity,
    duration: 1,
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="34"
      fill="none"
      viewBox="0 0 300 34"
    >
      <motion.g
        initial={animations.exit}
        animate={hovered ? animations.open : animations.exit}
        exit={animations.exit}
        transition={hovered && transition}
      >
        <path
          d="M4.20336 23.8757L0.00012207 16.6478C2.79795 15.0323 5.12128 12.7153 6.73851 9.92778C8.35574 7.14024 9.21034 3.97956 9.2171 0.760895H17.6089C17.6099 5.5185 16.3322 10.1898 13.9082 14.2907C11.5549 18.2625 8.21246 21.5637 4.20336 23.8757Z"
          fill="white"
        />
        <path
          d="M9.63824 33.2391L5.43103 26.0126C9.51292 23.6651 14.1446 22.4298 18.8593 22.4312C23.5661 22.4293 28.1904 23.6604 32.2675 26.0006L28.0669 33.2298C25.2669 31.6234 22.0913 30.7789 18.8593 30.7812C15.6218 30.7793 12.4412 31.6272 9.63824 33.2391Z"
          fill="white"
        />
        <path
          d="M33.4939 23.8757C29.4858 21.5648 26.1435 18.2656 23.789 14.296C21.365 10.1951 20.0873 5.52381 20.0884 0.766205H28.4802C28.4872 3.98482 29.3419 7.14539 30.9591 9.93289C32.5763 12.7204 34.8995 15.0375 37.6972 16.6531L33.4939 23.8757Z"
          fill="white"
        />
      </motion.g>

      <path
        d="M70.3805 25.9688C70.3878 26.4325 70.2996 26.8928 70.1212 27.3212C69.9428 27.7496 69.6781 28.1372 69.3434 28.4599C69.0191 28.7929 68.6296 29.0563 68.199 29.2338C67.7684 29.4113 67.3058 29.4991 66.8398 29.4919H52.6784V25.9688H66.3346V18.9227H56.2191C55.753 18.9301 55.2903 18.8424 54.8595 18.6652C54.4287 18.4879 54.0389 18.2248 53.7142 17.892C53.3799 17.5691 53.1156 17.1815 52.9374 16.7531C52.7593 16.3246 52.6711 15.8645 52.6784 15.4009V8.35482C52.6711 7.89126 52.7593 7.43112 52.9374 7.00271C53.1156 6.57431 53.3799 6.1867 53.7142 5.86374C54.0389 5.53077 54.4286 5.26744 54.8594 5.08997C55.2902 4.91249 55.7529 4.82462 56.2191 4.83176H70.3805V8.35482H56.7243V15.4009H66.8425C67.3084 15.3937 67.7708 15.4814 68.2014 15.6587C68.6319 15.8359 69.0215 16.099 69.346 16.4316C69.6805 16.7545 69.945 17.1421 70.1234 17.5705C70.3018 17.9988 70.3902 18.459 70.3832 18.9227L70.3805 25.9688Z"
        fill="white"
      />
      <path
        d="M103.255 29.4414H99.2086V10.9215L100.726 8.40124H98.4501L92.2286 22.4417H89.2958L83.2769 8.40124H81.0013L82.5184 10.9215V29.4414H78.4724V4.83176H85.7044L90.7115 16.608H91.2181L96.2252 4.83176H103.255V29.4414Z"
        fill="white"
      />
      <path
        d="M133.601 29.4919H129.555V22.4457H116.404V29.4919H112.358V8.35483C112.351 7.89113 112.439 7.43086 112.618 7.00242C112.796 6.57398 113.061 6.18645 113.395 5.86375C113.72 5.53052 114.11 5.26703 114.54 5.08953C114.971 4.91203 115.434 4.82431 115.9 4.83177H133.602L133.601 29.4919ZM129.555 18.9227V8.35483H116.404V21.943L119.44 18.9227H129.555Z"
        fill="white"
      />
      <path
        d="M164.453 29.4919H159.294L148.774 18.9227H159.9V8.35488H146.751V29.4919H142.705V11.8275L146.245 8.35488H142.705V4.83182H160.407C160.871 4.83105 161.33 4.9248 161.756 5.1073C162.182 5.2898 162.567 5.55718 162.885 5.89298C163.223 6.20973 163.491 6.59194 163.675 7.01605C163.858 7.44015 163.952 7.89715 163.952 8.35886V17.9199C163.952 20.6047 162.772 21.947 160.412 21.947H157.377V22.5002L164.453 29.4919Z"
        fill="white"
      />
      <path
        d="M186.201 8.35482H178.869V29.4918H174.823V8.35482H167.491V4.83176H186.205L186.201 8.35482Z"
        fill="white"
      />
      <path
        d="M195.051 29.4918H191.005V4.83176H195.051V29.4918Z"
        fill="white"
      />
      <path
        d="M225.144 25.9688C225.152 26.4325 225.063 26.8928 224.885 27.3212C224.707 27.7497 224.442 28.1372 224.107 28.4599C223.783 28.7931 223.394 29.0567 222.963 29.2342C222.532 29.4117 222.07 29.4994 221.604 29.4919H207.442C206.976 29.4994 206.514 29.4117 206.083 29.2342C205.652 29.0567 205.263 28.7931 204.939 28.4599C204.604 28.1373 204.339 27.7498 204.161 27.3214C203.982 26.8929 203.894 26.4325 203.902 25.9688V8.35484C203.894 7.89114 203.983 7.43087 204.161 7.00243C204.339 6.57399 204.604 6.18646 204.939 5.86376C205.263 5.5307 205.652 5.2673 206.083 5.08981C206.514 4.91232 206.976 4.8245 207.442 4.83178H221.604C222.07 4.8245 222.532 4.91232 222.963 5.08981C223.393 5.2673 223.783 5.5307 224.107 5.86376C224.442 6.18665 224.706 6.57422 224.885 7.00261C225.063 7.43101 225.151 7.89119 225.144 8.35484V11.8779H221.098V8.35484H207.948V25.9688H221.098V22.4458H225.144V25.9688Z"
        fill="white"
      />
      <path
        d="M253.972 29.4919H249.927V22.4458H236.777V29.4919H232.731V8.35485C232.724 7.89128 232.812 7.43114 232.99 7.00274C233.168 6.57434 233.433 6.18673 233.767 5.86377C234.091 5.53084 234.481 5.26752 234.911 5.09004C235.342 4.91256 235.804 4.82467 236.27 4.83179H253.972V29.4919ZM249.927 18.9227V8.35485H236.777V21.943L239.811 18.9227H249.927Z"
        fill="white"
      />
      <path
        d="M277.491 8.35482H270.159V29.4918H266.113V8.35482H258.781V4.83176H277.495L277.491 8.35482Z"
        fill="white"
      />
      <path
        d="M300 29.4918H282.295V11.8779L285.836 8.35482H282.295V4.83176H300V8.35482H286.341V18.4199L289.375 15.4009H300V18.9227H286.341V25.9688H300V29.4918Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
