import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

export interface CasesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cases
  location?: any
}

interface PostProps {
  [key: string]: any
}

const CasesShell: React.FC<CasesProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    overview: loadable(() => import('components/flex/Cases/Overview')),
    highlighted: loadable(() => import('components/flex/Cases/Highlighted')),
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default CasesShell
