const mainTheme = {
  color: {
    primary: '#FF0000',
    secondary: '#EB6209',
    grey: '#F0F0F0',
    dark: '#000000',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'Fira Sans, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      bigger: '20px',
      large: '24px',
      larger: '30px',
      huge: '36px',
      mega: '45px',
      giga: '50px',
    },

    weight: {
      light: 300,
      regular: 400,
      semiBold: 500,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
