/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Item = styled(motion.li)`
  font-family: ${({ theme }) => theme.font.family.primary};

  & div > a {
    color: ${(props) => props.theme.color.light};

    &[aria-current] {
      & > span {
        border-top-color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  @media (max-width: 1439px) {
    & div > a {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }

  @media (min-width: 1440px) {
    & div > a {
      padding: 3.2rem 3rem 3.5rem 3rem;
    }
  }

  @media (min-width: 992px) and (max-width: 1439px) {
    & div > a {
      padding: 0.75rem;
    }
  }

  @media (min-width: 992px) {
    & div > a {
      ${({ open }) =>
        open
          ? css`
              background-color: ${({ theme }) => theme.color.light};
              color: ${({ theme }) => theme.color.dark};
              font-weight: ${({ theme }) => theme.font.weight.semiBold};
            `
          : css`
              background-color: transparent;
              color: ${({ theme }) => theme.color.light};
            `};
    }
  }

  @media (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      min-width: 160px;
    }
  }
`

const InnerLink = styled.span<{ open: boolean }>`
  ${({ open }) =>
    open
      ? css`
          border-top: 2px solid ${({ theme }) => theme.color.primary};
        `
      : css`
          border-top: 2px solid ${({ theme }) => theme.color.light};
        `};

  @media (max-width: 991px) {
    width: 100%;
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 15px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          key={index}
          open={open === index && l?.submenu}
        >
          <div className="d-flex justify-content-between justify-content-lg-start align-items-center flex-wrap py-3 py-lg-0">
            <NavLink to={l?.link?.url || '/'}>
              <InnerLink open={open === index} className="d-inline-block pt-2">
                {l?.link?.title}
              </InnerLink>
            </NavLink>
            {l?.submenu && (
              <div className="d-block d-lg-none">
                <SvgWrapper
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  open={open === index}
                >
                  <Chevron />
                </SvgWrapper>
              </div>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && <Submenu fields={l.submenu} />}
            </AnimatePresence>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
