import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import NavLink from 'components/shared/NavLink'

// Images
import Circle from 'img/circlesmall.svg'
import Location from 'img/location.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const Section = styled.div`
  background-color: ${(props) => props.theme.color.dark};

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.medium};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
  }

  & ul {
    list-style: none;
    li {
      position: relative;
      margin-left: 0.25rem;
      margin-bottom: 1rem;
      font-size: ${({ theme }) => theme.font.size.medium};
      &:before {
        position: absolute;
        left: -20px;
        top: 0;
        content: url(${Circle});
        width: 13px;
        height: 13px;
      }
    }
  }

  & a:hover {
    color: ${({ theme }) => theme.color.primary};
  }

  & p,
  & a,
  & i,
  & span {
    color: ${({ theme }) => theme.color.light};
  }
`

const Documents = styled.div`
  & a,
  & span {
    padding-left: 0.5rem;

    &:before {
      content: '|';
      color: ${({ theme }) => theme.color.light};
      padding-right: 0.5rem;
    }

    &:first-child {
      padding-left: unset;

      &:before {
        content: unset;
      }
    }
  }

  & i {
    opacity: 0.85;
  }

  @media (min-width: 1440px) {
    & > div {
      flex-direction: row;
    }
  }

  @media (max-width: 1439px) {
    & > div {
      flex-direction: column;
    }
  }
`

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          contactFooter {
            street
            postal
            phone {
              title
              url
            }
            email {
              title
              url
            }
            button {
              title
              url
            }
            privacy {
              title
              url
            }
            cookies {
              title
              url
            }
            extrainfo
          }
        }
      }
    }
  `)

  const {
    street,
    postal,
    phone,
    email,
    button,
    privacy,
    cookies,
    extrainfo,
  }: any = fields?.footer?.contactFooter

  const date = new Date()

  return (
    <Section className="pb-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="row">
              {fields?.footer?.menuFooter?.map(({ title, menu }: any) => (
                <div className="col-6" key={title}>
                  <h2 className="mb-3">{title}</h2>
                  <ul className="ps-3">
                    {menu.map(({ link }: any) => (
                      <li>
                        <NavLink to={link.url}>{link.title}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-7 mt-4 mt-lg-0">
            <h2 className="mb-3">CONTACT</h2>
            <div className="row mb-4">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <Location />
                  </div>
                  <div className="col-10 gx-1">
                    <p className="mb-1">{street}</p>
                    <p>{postal}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-2">
                    <Phone />
                  </div>
                  <div className="col-10 gx-1 mb-1">
                    <NavLink to={phone.url}>{phone.title}</NavLink>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <Email />
                  </div>
                  <div className="col-10 gx-1">
                    <NavLink to={email.url}>{email.title}</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0">
                <ButtonSecondary to={button.url || '#'}>
                  {button.title}
                </ButtonSecondary>
              </div>
            </div>
            <Documents className="row">
              <div className="d-flex">
                <span>&copy; {date.getFullYear()}</span>
                <NavLink to={privacy.url}>{privacy.title}</NavLink>
                <NavLink to={cookies.url}>{cookies.title}</NavLink>
                <span>
                  <i>{extrainfo}</i>
                </span>
              </div>
            </Documents>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Footer
