import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

export interface BannerProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell: React.FC<BannerProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    default: loadable(() => import('components/flex/Banner/BannerDefault')),
    landing: loadable(() => import('components/flex/Banner/BannerLanding')),
    usps: loadable(() => import('components/flex/Banner/BannerUsps')),
    detail: loadable(() => import('components/flex/Banner/BannerDetail')),
    image: loadable(() => import('components/flex/Banner/BannerImage')),
    small: loadable(() => import('components/flex/Banner/BannerSmall')),
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerShell
