/* eslint-disable no-underscore-dangle */
import React from 'react'

// Shells import
import BannerShell from 'components/flex/Banner/BannerShell'
import FormShell from 'components/flex/Form/FormShell'
import TextShell from 'components/flex/Text/TextShell'
import PostsShell from 'components/flex/Posts/PostsShell'
import Fragment from 'components/flex/Fragment'
import CasesShell from 'components/flex/Cases/CasesShell'
import SliderShell from 'components/flex/Slider/SliderShell'
import FunctionalitiesShell from 'components/flex/Functionalities/FunctionalitiesShell'
import Whitespace from 'components/flex/Misc/Whitespace'
import PartnersShell from 'components/flex/Partners/PartnersShell'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Functionalities`]: FunctionalitiesShell,
    [`${prefix}_Flex_PostTypes`]: PostsShell,
    [`${prefix}_Flex_Cases`]: CasesShell,
    [`${prefix}_Flex_Whitespace`]: Whitespace,
    [`${prefix}_Flex_Partners`]: PartnersShell,
    [`${prefix}_Flex_Fragment`]: Fragment,
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
